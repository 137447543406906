export const ptBr = {
    "search": "Procurar",
    "search_no_results_1": "Oh não!",
    "search_no_results_2": "Esse emoji não foi encontrado",
    "pick": "Escolha um emoji...",
    "add_custom": "Adicione um emoji customizado",
    "categories": {
        "activity": "Atividade",
        "custom": "Customizado",
        "flags": "Bandeiras",
        "foods": "Comidas & Bebidas",
        "frequent": "Usados recentemente",
        "nature": "Animais & Natureza",
        "objects": "Objetos",
        "people": "Carinhas & Pessoas",
        "places": "Viagens & Lugares",
        "search": "Resultados encontrados",
        "symbols": "Símbolos"
    },
    "skins": {
        "choose": "Escolha a cor de pele padrão",
        "1": "Padrão",
        "2": "Clara",
        "3": "Morena-Clara",
        "4": "Parda",
        "5": "Morena-Escura",
        "6": "Preta"
    }
};

